import { AssessmentOverview } from '@/modules/client/models/assessment-overview';
import { CategoryType } from '@/core/category-type';
import {
  BusinessUnitType,
  MainWidgetResponseType,
} from '@/core/types/overview';
import { ClientSupplierDetailType } from '@/core/types/suppliers';
import http from '@/services/http';
import { SupplierRatingEnum } from '@/core/enums/suppliers';
import { Dashboard } from '@/modules/client/models/dashboard';
import {
  AssessmentTypeList,
  AssessmentType,
  AssessmentDetailType,
} from '@/core/types/assessments';
import { QuestionType } from '@/core/types/questions';
import { DirectionEnum } from '@/core/enums/direction';
import { ClientUserType } from '@/modules/client/models/users/client-user.type';
import { UpdateClientProfileDto } from '@/modules/client/models/profile/update-client-profile.dto';
import { ClientProfileNewType } from '@/modules/client/models/profile/client-profile-new.type';
import { PaginatedResponse } from '@/core/models/paginated-response.type';
import { ClientSupplierListItem } from '@/modules/client/models/suppliers/client-supplier-list-item.type';
import { SupplierContactType } from '@/modules/client/models/suppliers/supplier-contact.type';
import { SupplierContactDto } from '@/modules/client/models/suppliers/supplier-contact.dto';
import { CreateUserDto } from '@/modules/client/models/users/create-user.dto';
import { MoveToSupplierDto } from '@/modules/client/models/assessments/move-to-supplier.dto';
import { InfoNumberEnum } from '@/core/enums/info_number';
import { QuestionLogType } from '@/core/models/question-log.type';
import { SubscriptionTierCardType } from '@/core/types/subscription';

export type QueryType = { [key: string]: string | number | any };

const resource = `${process.env.VUE_APP_API_URL}clients/`;

// ─── SIGNUP API ────────────────────────────────────────────────────
const signUp = (data: QueryType): any =>
  http.postWithoutCredentials(
    `${process.env.VUE_APP_API_URL}clients/users/sign_up/`,
    data
  );
const resendLink = (data: QueryType): any =>
  http.post(`${process.env.VUE_APP_API_URL}clients/users/resend_email/`, data);
const completeRegistration = (data: QueryType): any =>
  http.post(
    `${process.env.VUE_APP_API_URL}clients/users/complete_registration/`,
    data
  );
const completeSocialRegistration = (data: QueryType): any =>
  http.post(
    `${process.env.VUE_APP_API_URL}clients/users/complete_social_registration/`,
    data
  );
const fetchProductfruitData = (): Promise<any> =>
  http.get(`${process.env.VUE_APP_API_URL}clients/users/productfruit_data/`);

// ─── Contact Us ────────────────────────────────────────────────────
const contactUs = (data: QueryType): any =>
  http.post(`${process.env.VUE_APP_API_URL}subscriptions/contact_us/`, data);

// ─── CLIENT DASHBOARD API ────────────────────────────────────────────────────
const fetchDashboard = (): Promise<Dashboard> =>
  http.get(`${resource}dashboard/`);
const fetchMainWidget = (): Promise<MainWidgetResponseType> =>
  http.get(`${resource}dashboard/main_widget/`);
const fetchSubscription = (): any =>
  http.get(`${resource}dashboard/subscription/`);
const fetchSubscriptionData = (): any =>
  http.get(
    `${process.env.VUE_APP_API_URL}subscriptions/plans/subscription_data/`
  );
const fetchInvoices = (): any =>
  http.get(`${process.env.VUE_APP_API_URL}subscriptions/plans/invoice_list/`);
const upgradePlan = (id: number, data: QueryType): any =>
  http.post(
    `${process.env.VUE_APP_API_URL}subscriptions/plans/${id}/subscribe/`,
    data
  );
const fetchLicenseProducts = (): any =>
  http.get(`${process.env.VUE_APP_API_URL}subscriptions/license_products/`);
const requestEnterprisePlan = (data: QueryType): any =>
  http.post(
    `${process.env.VUE_APP_API_URL}subscriptions/request/enterprise_plan/`,
    data
  );
const cancelPlan = (data: QueryType): any =>
  http.post(`${process.env.VUE_APP_API_URL}subscriptions/plans/cancel/`, data);
const fetchPaymentDetails = (): any =>
  http.get(
    `${process.env.VUE_APP_API_URL}subscriptions/plans/portal_session_url/`
  );
const fetchPreviewMorePro = (data: QueryType): any =>
  http.post(
    `${process.env.VUE_APP_API_URL}subscriptions/license_products/preview_buy/`,
    data
  );
const fetchMoreProBuy = (data: QueryType): any =>
  http.post(
    `${process.env.VUE_APP_API_URL}subscriptions/license_products/buy/`,
    data
  );
const fetchPreviewSubscribe = (id: number, data: QueryType): any =>
  http.post(
    `${process.env.VUE_APP_API_URL}subscriptions/plans/${id}/preview_subscribe/`,
    data
  );
const tiers = (params: string | null): Promise<SubscriptionTierCardType[]> => {
  return http.get(
    `${process.env.VUE_APP_API_URL}subscriptions/plans${params ?? ''}`
  );
};
// ───  ────────────────────────────────────────────────────────────────────────

// ─── CLIENT ASSESSMENTS API ──────────────────────────────────────────────────
const fetchAssessments = (query: QueryType): Promise<AssessmentTypeList> =>
  http.get(`${resource}assessments/`, { params: query });

const fetchAssessmentsExport = (query: QueryType): Promise<any> =>
  http.get(`${resource}assessments/export/`, { params: query });

const fetchAssessmentsOverview = (
  query: QueryType
): Promise<AssessmentOverview> =>
  http.get(`${resource}assessments/overview/`, { params: query });

const createAssessment = (data: QueryType): Promise<Record<string, any>> =>
  http.post(`${resource}assessments/`, data);

const fetchAssessmentById = (id: number): Promise<AssessmentDetailType> =>
  http.get(`${resource}assessments/${id}/`);

const removeAssessmentById = (id: number): Promise<AssessmentType> =>
  http.delete(`${resource}assessments/${id}/`);

const fetchAssessmentShortOverview = (
  query: QueryType
): Promise<AssessmentOverview> =>
  http.get(`${resource}assessments/short_overview/`, { params: query });

const moveAssessmentToSupplier = (id: number, data: MoveToSupplierDto) =>
  http.post(`${resource}assessments/${id}/move_to_supplier/`, data);

const moveAssessmentToEvaluator = (id: number) =>
  http.post(`${resource}assessments/${id}/move_to_evaluator/`, {});

const requestProEvaluation = (id: number) => {
  return http.post(
    `${resource}assessments/${id}/request_expert_evaluation/`,
    {}
  );
};

const completeAssessment = (id: number) =>
  http.post(`${resource}assessments/${id}/complete/`, {});

const setAssessmentRating = (id: number, rating: InfoNumberEnum) =>
  http.post(`${resource}assessments/${id}/set_rating/`, { rating });

const repeatAssessment = (id: number) =>
  http.post(`${resource}assessments/${id}/repeat/`, {});
// ─────────────────────────────────────────────────────────────────────────────

// ─── CLIENT ASSESSMENT QUESTIONS API ─────────────────────────────────────────
const fetchAssessmentQuestionsByCriticality = (query: {
  [key: string]: string | number;
}) =>
  http.get(`${resource}assessment_questions/by_criticality/`, {
    params: query,
  });

const fetchAssessmentQuestionsByCategory = (query: {
  [key: string]: string | number;
}) =>
  http.get(`${resource}assessment_questions/by_category/`, { params: query });

const fetchAssessmentQuestions = (query: {
  [key: string]: string | number;
}): Promise<Array<QuestionType>> =>
  http.get(`${resource}assessment_questions/`, {
    params: query,
  });

const exportAssessmentQuestions = (query: {
  [key: string]: string | number;
}): Promise<string> =>
  http.get(`${resource}assessment_questions/export/`, { params: query });

const deleteQuestion = (id: number, assessment_id: number) =>
  http.delete(`${resource}assessment_questions/${id}/`, {
    params: { assessment_id },
  });

const editQuestion = (
  id: number,
  assessment_id: number,
  data: { [key: string]: string }
) =>
  http.put(`${resource}assessment_questions/${id}/`, data, {
    params: { assessment_id },
  });
const fetchAssessmentQuestionsCount = (query: QueryType) =>
  http.get(`${resource}assessment_questions/count/`);

const respondAssessmentQuestion = (
  id: number,
  assessment_id: number,
  data: { [key: string]: string }
) => {
  const apiData = {
    short_answer: data.short_answer,
    justification: data.justification,
    attachments: data.attachments,
  };
  return http.post(`${resource}assessment_questions/${id}/respond/`, apiData, {
    params: { assessment_id },
  });
};

const evaluateAssessmentQuestion = (
  id: number,
  assessment_id: number,
  data: { [key: string]: string }
) =>
  http.post(`${resource}assessment_questions/${id}/evaluate/`, data, {
    params: { assessment_id },
  });

// ─────────────────────────────────────────────────────────────────────────────

// ─── CLIENT SUPPLIERS API ────────────────────────────────────────────────────

const createOption = (data: any) => http.post(`${resource}options/`, data);
const editOption = (id: number, data: any) =>
  http.patch(`${resource}options/${id}/`, data);
const removeOption = (id: number) => http.delete(`${resource}options/${id}/`);

const fetchSuppliersCategories = () =>
  http.get(`${resource}options?kind=CATEGORY`);
const fetchSuppliersCompliance = () =>
  http.get(`${resource}options?kind=COMPLIANCE`);

const fetchSupplierById = (id: number): Promise<ClientSupplierDetailType> =>
  http.get(`${resource}suppliers/${id}/`);
const fetchSuppliersExport = (query: QueryType): Promise<any> =>
  http.get(`${resource}suppliers/export/`, { params: query });
const fetchSuppliers = (): Promise<ClientSupplierListItem[]> =>
  http.get(`${resource}suppliers/`);
const fetchSuppliersPaginated = (query: {
  [key: string]: string | number;
}): Promise<PaginatedResponse<ClientSupplierListItem>> =>
  http.get(`${resource}suppliers/`, { params: query });
const fetchSuppliersShort = (query?: { [key: string]: string | number }) =>
  http.get(`${resource}suppliers/short_list/`, { params: query });
const fetchSuppliersOverview = (query?: { [key: string]: string | number }) =>
  http.get(`${resource}suppliers/overview/`, { params: query });
const createSupplier = (data?: { [key: string]: string }) =>
  http.post(`${resource}suppliers/`, data);
const checkSupplier = (contact_email: string) =>
  http.post(`${resource}suppliers/check_supplier/`, { contact_email });

const addSupplier = (contact_email: string) =>
  http.post(`${resource}suppliers/add_supplier/`, { contact_email });

const editSupplier = (
  id: number,
  data: Partial<ClientSupplierDetailType>
): Promise<ClientSupplierDetailType> =>
  http.patch(`${resource}suppliers/${id}/`, data);

const editSupplierName = (id: number, data: QueryType): any =>
  http.post(`${resource}suppliers/${id}/update_name/`, data);

const deleteSupplier = (id: number) =>
  http.delete(`${resource}suppliers/${id}/`);

const setSupplierRating = (id: number, rating: SupplierRatingEnum) =>
  http.post(`${resource}suppliers/${id}/set_rating/`, { rating });
const fetchSupplierQuestionnaireCategories = (): Promise<Array<CategoryType>> =>
  http.get(
    `${process.env.VUE_APP_API_URL}questionnaires/questionnaire_categories/`
  );
const fetchSupplierLogs = (
  id: number,
  clientId?: number
): Promise<QuestionLogType[]> =>
  http.get(`${resource}suppliers/${id}/logs/`, {
    params: { client_id: clientId },
  });

const addSupplierLog = (id: number, text: string, clientId?: number) =>
  http.post(
    `${resource}suppliers/${id}/add_log/`,
    { text },
    { params: { client_id: clientId } }
  );

// ─────────────────────────────────────────────────────────────────────────────

// ─── CLIENT CONTROLS API ─────────────────────────────────────────────────────
const fetchControls = (query: QueryType) =>
  http.get(`${resource}controls/`, { params: query });
const fetchControlsCount = (query: QueryType) =>
  http.get(`${resource}controls/count`, { params: query });
const fetchControlsByCategory = (query: QueryType) =>
  http.get(`${resource}controls/by_category/`, { params: query });
const fetchControlsByCriticality = (query: QueryType) =>
  http.get(`${resource}controls/by_criticality/`, { params: query });
// ─────────────────────────────────────────────────────────────────────────────

// ─── CLIENT BUSINESS UNITS API ───────────────────────────────────────────────
const createBusinessUnit = (data: BusinessUnitType) =>
  http.post(`${resource}business_units/`, data);
const editBusinessUnit = (id: number, data: BusinessUnitType) =>
  http.patch(`${resource}business_units/${id}/`, data);
const deleteBusinessUnit = (id: number) =>
  http.delete(`${resource}business_units/${id}/`);
const moveBusinessUnit = (
  id: number,
  position: DirectionEnum,
  other_unit: number
) =>
  http.post(`${resource}business_units/${id}/move/`, { position, other_unit });
const addClientSupplierToUnit = (id: number, supplier: number) =>
  http.post(`${resource}business_units/${id}/add_supplier/`, {
    supplier,
  });
const deleteSupplierFromUnit = (id: number, supplier: number) =>
  http.post(`${resource}business_units/${id}/remove_supplier/`, {
    supplier,
  });
const fetchBusinessUnits = (): Promise<Array<BusinessUnitType>> =>
  http.get(`${resource}business_units/`);
// ─────────────────────────────────────────────────────────────────────────────

// ─── CLIENT PROFILE API ──────────────────────────────────────────────────────

const fetchProfile = (): Promise<ClientProfileNewType> =>
  http.get(`${resource}profile/get/`);
const updateProfile = (
  data: UpdateClientProfileDto
): Promise<ClientProfileNewType> => http.post(`${resource}profile/edit/`, data);

// ─────────────────────────────────────────────────────────────────────────────

// ─── CLEINT USERS API ────────────────────────────────────────────────────────
const fetchUsers = (): Promise<ClientUserType[]> =>
  http.get(`${resource}users/`);
const createUser = (data: CreateUserDto) =>
  http.post(`${resource}users/`, data);
const resetPassword = (data: { old_password: string; new_password: string }) =>
  http.post(`${process.env.VUE_APP_API_URL}users/reset_password/`, data);
const deactivateUser = (id: number) =>
  http.post(`${resource}users/${id}/deactivate/`, {});
const deleteUser = (id: number) => http.delete(`${resource}users/${id}/`, {});
const makeMain = (id: number) =>
  http.post(`${resource}users/${id}/make_main/`, {});
const editUser = (id: number | string, data: CreateUserDto) =>
  http.put(`${resource}users/${id}/`, data);
const resendInvite = (id: number | string) =>
  http.post(`${resource}users/${id}/resend_invite/`, {});
const fetchUserById = (id: number | string): Promise<ClientUserType> =>
  http.get(`${resource}users/${id}/`);
const getZapierApiKey = (): Promise<any> =>
  http.post(
    `${process.env.VUE_APP_API_URL}external_integration/get_api_key/`,
    {}
  );
// ─────────────────────────────────────────────────────────────────────────────

// ─── CLIENT LICENSES API ─────────────────────────────────────────────────────
const licenseRequest = (data: any) =>
  http.post(`${process.env.VUE_APP_API_URL}licenses/request/`, data);
// ─────────────────────────────────────────────────────────────────────────────

// ─── CLIENT->SUPPLIER CONTACTS API ─────────────────────────────────────────────────────
const getClientSupplierContactsList = (
  supplierId: number
): Promise<SupplierContactType[]> =>
  http.get(`${resource}supplier_contacts/?supplier_id=${supplierId}`);

const createClientSupplierContact = (
  supplierId: number,
  data: SupplierContactDto
) => http.post(`${resource}supplier_contacts/?supplier_id=${supplierId}`, data);

const updateClientSupplierContact = (
  supplierId: number,
  contactId: number,
  data: SupplierContactDto
) =>
  http.patch(
    `${resource}supplier_contacts/${contactId}/?supplier_id=${supplierId}`,
    data
  );

const deleteClientSupplierContact = (supplierId: number, contactId: number) =>
  http.delete(
    `${resource}supplier_contacts/${contactId}/?supplier_id=${supplierId}`
  );

// ─────────────────────────────────────────────────────────────────────────────

export default {
  signUp,
  resendLink,
  completeRegistration,
  completeSocialRegistration,
  fetchProductfruitData,
  contactUs,
  fetchAssessments,
  fetchAssessmentsExport,
  fetchAssessmentById,
  removeAssessmentById,
  fetchAssessmentShortOverview,
  fetchAssessmentsOverview,
  fetchDashboard,
  fetchSuppliers,
  fetchSuppliersPaginated,
  fetchSuppliersOverview,
  createSupplier,
  createOption,
  editOption,
  removeOption,
  fetchSuppliersCategories,
  fetchSuppliersCompliance,
  fetchSupplierById,
  fetchSuppliersExport,
  setSupplierRating,
  checkSupplier,
  fetchSuppliersShort,
  editSupplier,
  editSupplierName,
  addSupplier,
  deleteSupplier,
  fetchSupplierQuestionnaireCategories,
  createAssessment,
  fetchAssessmentQuestionsByCriticality,
  fetchAssessmentQuestionsByCategory,
  moveAssessmentToSupplier,
  moveAssessmentToEvaluator,
  requestProEvaluation,
  completeAssessment,
  setAssessmentRating,
  repeatAssessment,
  fetchAssessmentQuestions,
  exportAssessmentQuestions,
  editQuestion,
  deleteQuestion,
  fetchControls,
  fetchControlsByCategory,
  fetchControlsByCriticality,
  fetchControlsCount,
  fetchAssessmentQuestionsCount,
  respondAssessmentQuestion,
  evaluateAssessmentQuestion,
  fetchProfile,
  updateProfile,
  licenseRequest,
  fetchUsers,
  createUser,
  resetPassword,
  getZapierApiKey,
  deactivateUser,
  deleteUser,
  makeMain,
  editUser,
  resendInvite,
  fetchUserById,
  fetchSubscription,
  fetchSubscriptionData,
  fetchInvoices,
  upgradePlan,
  fetchLicenseProducts,
  requestEnterprisePlan,
  cancelPlan,
  tiers,
  fetchPaymentDetails,
  fetchMoreProBuy,
  fetchPreviewMorePro,
  fetchPreviewSubscribe,
  fetchBusinessUnits,
  deleteSupplierFromUnit,
  addClientSupplierToUnit,
  moveBusinessUnit,
  fetchMainWidget,
  createBusinessUnit,
  deleteBusinessUnit,
  editBusinessUnit,
  getClientSupplierContactsList,
  createClientSupplierContact,
  updateClientSupplierContact,
  deleteClientSupplierContact,
  fetchSupplierLogs,
  addSupplierLog,
};
